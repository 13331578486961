/* header login */
.header-reset-password {
  margin-bottom: 60px;
}

.header-reset-password > h2 {
  text-align: left;
  font-family: Montserrat-Bold, sans-serif;
  font-size: 50px;
  font-weight: bold;
}

.header-reset-password > h5 {
  font-family: Montserrat-Medium, sans-serif;
  text-align: left;
  font-size: 30px;
}

.footer-reset-password {
  font-family: Montserrat-Medium, sans-serif;
  text-align: left;
  margin-top: 50px;
}

.error-message-container {
  margin-top: 10px;
  padding: 10px;
}

.error-message-container > .error-message {
  text-align: center;
  font-size: 11px;
  font-weight: bold;
}

.information-message-container {
  margin-top: 10px;
  padding: 10px;
}

.information-message-container > .information-message {
  text-align: center;
  font-size: 11px;
  color: green;
  font-weight: bold;
}

.center-block {
  text-align: center;
}

.center-block > .token-error-message {
  font-size: 15px;
  color: red;
  font-weight: bold;
}

.welcome {
  color: black;
  font-weight: 900;
  font-size: 31px;
}

.container-form {
  padding-left: 100px;
}

@media screen and (max-width: 1308px) {
  .container-form {
    padding-left: 50px;
  }
}

@media screen and (max-width: 1118px) {
  .container-form {
    padding-left: 10px;
  }
}

.label {
  color: #878f9c;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
  letter-spacing: -0.6px;
  margin-bottom: 11px !important;
}

.custom-input-reset {
  width: 465px !important;
  height: 40px !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 17px !important;
}

.custom-input-reset::placeholder {
  font-size: 14px;
  letter-spacing: 0.5px;
  padding-left: 10px;
  color: #878f9c;
}

@media screen and (max-width: 1550px) {
  .custom-input-reset {
    width: 400px !important;
  }
}

.back-login {
  color: #878f9c;
  text-decoration: underline;
  text-decoration-color: #878f9c;
  font-size: 14px;
}

.p-info {
  color: #878f9c;
  text-decoration-color: #878f9c;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.change-password-btn {
  height: 37px !important;
  width: 250px !important;
  background-color: #079992 !important;
  border-radius: 11px !important;
  border-bottom: 4px solid #0a8a84 !important;
  color: white !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  text-decoration: underline !important;
  text-decoration-color: #079992 !important;
}

btn-login:active {
  border-bottom: 1px solid #06726c !important;
}

btn-login:hover {
  background-color: #10a39c !important;
}

.background-img {
  /* background: url(/src/assets/images/background/background_login.png) no-repeat; */
  background-position: center;
  background-size: fill;
  height: 100%;
  width: 100%;
}

.logo-login {
  object-fit: contain;
  height: 200px;
  width: 300px;
}
