:root {
  --swiper-navigation-size: 15px;
  --swiper-navigation-color: white;
  --toastify-color-info: #079992 !important;
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif !important;
}

.App {
  text-align: left;
  display: flex;
  flex-direction: row;
}

.App-root {
  background-color: #079992;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .fleet-item-status::after,
  .live-signal::before {
    animation: status-blink infinite 1s linear;
  }
}

body .badge-principal {
  color: #fff;
  background-color: #079992 !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.testClass {
  color: red;
}

.App-nav {
  position: relative;
  background-color: #079992;
  min-height: 100vh;
  font-size: 30px;
  color: black;
  width: 240px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  transition: all 0.1s;

  &:hover {
    .collapse-toggle {
      opacity: 1;
      background-color: #f7f7f9;
    }
  }

  &.collapsed {
    width: 96px;

    .App-nav-wrapper .brand-logo-fleet-client > a > img,
    .App-nav-wrapper .brand-logo-fleet > a > img {
      width: 50%;
    }

    .collapse-toggle > .icon {
      transform: rotate(180deg);
    }
  }

  .collapse-toggle {
    position: absolute;
    top: 50px;
    transition:
      background-color 100ms linear 0s,
      color 100ms linear 0s,
      opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s;
    border-radius: 13px;
    border: none;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;

    right: -12px;
    width: 24px;
    height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    opacity: 0;

    .icon {
      width: 10px;
      height: 10px;
      color: #079992;
      transform-origin: 50% 50%;
    }

    &:hover {
      background-color: #c4e7e5;

      > .icon {
        color: #079992;
      }
    }

    &:active {
      background-color: #b6d6d5;
    }
  }

  .App-nav-wrapper {
    margin-top: 70px;
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
    height: calc(100% - 140px);

    .nav-list {
      padding: 0;
      list-style: none;
      text-decoration: none;
      width: 100%;
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > ul {
        padding: 0 10px;
        margin: 0;

        > li {
          transition: all 0.2s;
          display: flex;
          border-radius: 10px;
          margin-bottom: 2px;

          &.active {
            background-color: #07918a;
          }

          &:hover {
            background-color: #07918a;
          }

          > a,
          .menu-wrapper {
            color: white;
            flex: 1;
            padding: 20px 20px;
            text-decoration: none;
            font-size: 15px;
            line-height: 18px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &.border {
              border: 1px solid #4fb8b4;
              border-radius: 7px;
            }

            > .beta-tag {
              font-family: Verdana, sans-serif;
              align-self: flex-start;
              font-size: 8px;
              line-height: 1;
              padding: 2px 3px;
              color: #07918a;
              background-color: #fff;
              border-radius: 2px;
              margin-left: 4px;
              font-weight: 500;
              font-family: inherit;
            }

            > svg {
              margin-right: 10px;
              margin-left: 10px;
            }
          }
        }
      }
    }

    .brand-logo-fleet {
      flex: 0 0 auto;
      text-align: center;

      > a > img {
        width: 35%;
      }
    }

    .brand-logo-fleet-client {
      flex: 0 0 auto;
      text-align: center;

      > a > img {
        width: 35%;
      }
    }
  }
}

body .generic-button-fleet {
  background-color: #079992;
  border: none;
  border-radius: 5px;
  border-bottom: 2px solid #06726c;
}

.App-frame {
  &.collapsed {
    margin-left: 96px;
  }

  min-height: 100vh;
  height: 100vh;
  margin-left: 240px;
  display: flex;
  width: 100%;
  overflow-y: auto;
  position: relative;
  transition: all 0.1s;

  background: #ebecf0
    URL('./assets/images/background/background_decoration.png') no-repeat fixed
    0px 20px;
}
.container-fluid {
  padding: 0 !important;
}

/* Form generic part */
body .input-generic-fleet {
  padding: 1.8rem 0.45rem !important;
  font-family: Montserrat-Medium, sans-serif;
  border: none;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #a8a8a8;
  background-color: #eeeeee;
}

body .input-generic-fleet-prepend {
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
  border: none;
  padding: 6px 15px 6px 25px;
  color: black;
  background-color: #eeeeee;
}

.input-generic-fleet:focus {
  background-color: #eeeeee !important;
}

.input-generic-fleet-container {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  margin-bottom: 10px;
}

body .input-generic-fleet::placeholder {
  color: #cdcdcd;
}

body .button-generic-fleet-squared {
  background-color: #079992;
  color: white;
  padding: 19px;
  font-size: 19px;
  letter-spacing: 0.08rem;
  font-family: Montserrat-Bold, sans-serif;
  border-radius: 10px;
  margin-top: 30px;
}

body .button-generic-fleet-squared:hover {
  color: white;
}

.App-status-bar {
  /* height:60px; */
  background-color: #079992;
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: flex-start;
}

body .header-fleet {
  margin-top: 30px;
  text-align: left;
  position: relative;

  h3 {
    font-weight: 600;
    line-height: 1;
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    margin-bottom: 5px;
  }

  h4 {
    color: #8b8b8b;
    font-size: 12px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    line-height: 1;
  }

  .bg-deco-fleet {
    position: absolute;
    top: -43px;
    left: -200px;
    width: auto;
    z-index: 0;
  }
}

.header-fleet-center {
  text-align: right;
  position: relative;
  display: block;
  z-index: 2;
}

#filter-status-wrapper {
  display: flex;
  flex-direction: row;
}

.filter-status-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
}

.header-fleet-center > .btn-group-toggle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: 0 auto;
}

body .input-generic-fleet-searchbar::placeholder {
  color: #b2b2b2;
}

body .input-generic-fleet-searchbar {
}

body .input-generic-fleet-searchbar {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  margin-bottom: 10px;

  padding: 1rem 1.2rem !important;
  font-family: Montserrat-Medium, sans-serif;
  border: none;
  font-weight: 600;
  letter-spacing: 1px;
  color: #a8a8a8;
  background-color: #eeeeee;
}

body .input-generic-fleet-searchbar:focus {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  background-color: #eeeeee !important;
}

.input-generic-fleet-searchbar-grp {
  position: relative;
}

.center-vertical {
}

.search-button {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  right: 0;
  z-index: 3;
}

.fleet-first-line-tour {
  width: 100%;
  display: flex;
  padding: 15px;
  cursor: pointer;
}

.fleet-first-line-vehicle {
  width: 100%;
  display: flex;
  padding: 15px;
  align-items: center;
  flex-direction: row;
}

.fleet-other-item {
  flex-direction: row;
  display: flex;
}

.flex-item-input {
  display: block;
  flex-grow: 1;
  text-align: center;
}

.flex-item-input-label {
  font-family: Montserrat-Medium, sans-serif;
  color: #b4b4b4;
}

.flex-item-input-result {
  font-family: Montserrat-Medium, sans-serif;
  color: #000000;
}

/* Tour part */

.fleet-item {
  position: relative;
  display: block;
  background: white;
  border: 1px solid rgba(14, 14, 14, 0.1);
  border-radius: 5px;
  overflow: hidden;
  min-width: 600px;
  // transition: box-shadow 0.2s;
}
.squaredBtn {
  color: #a4a9b4;
  background-color: rgba(#adb4bc, 0.15);
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 20px;
}

.lightBtn {
  background-color: white;
  border: 1px solid #ced7e1;
  color: #079992;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Work Sans';
  padding: 8px 25px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
  }
}

.statusTag {
  font-size: 12px;
  padding: 5px 12px;
  text-align: center;
  width: 90px;
  border-radius: 5px;

  &.STARTED {
    background-color: rgba($color: #079992, $alpha: 0.2);
    color: #079992;
  }
  &.CREATED {
    background-color: rgba($color: #ced7e1, $alpha: 0.2);
    color: #909396;
  }
  &.late {
    background-color: rgba($color: #e58e26, $alpha: 0.2);
    color: #e58e26;
  }
  &.DONE {
    background-color: rgba($color: #81e9ae, $alpha: 0.2);
    color: #81e9ae;
  }
  &.ISSUE {
    background-color: rgba($color: #e58e26, $alpha: 0.2);
    color: #e58e26;
  }
}

.fleet-item:hover {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.23);
}

.fleet-first-line {
  display: flex;
  width: 100%;
}

.fleet-other-item {
  flex-direction: row;
  display: flex;
}

.flex-item-input {
  display: block;
  flex-grow: 1;
  text-align: center;
}

.flex-item-input-label {
  font-family: Montserrat-Medium, sans-serif;
  color: #b4b4b4;
}

.flex-item-input-result {
  font-family: Montserrat-Medium, sans-serif;
  color: #000000;
}

.fleet-item-collapsed {
  margin-top: 0px;
}

.fleet-item-steps-container-inner {
  padding: 0 15px 15px 15px;
}

.img-illustration-fleet {
  position: absolute;
  top: -50px;
  left: -25px;
  height: 150px;
  width: auto;
}

.img-illustration-fleet-step {
  position: absolute;
  top: -30px;
  left: -10px;
  height: 150px;
  width: auto;
}

.img-illustration-fleet-bl {
  position: absolute;
  z-index: -1;
  top: -31px;
  left: -20px;
  height: 80px;
  width: auto;
  opacity: 50%;
}

.img-illustration-fleet-2 {
  position: absolute;
  top: 5px;
  left: -5px;
  height: 150px;
  width: auto;
}

.fleet-item-desc2 {
  display: block;
  text-align: left;
  font-size: 14px;
  font-family: Montserrat-Medium, sans-serif;
  color: #bababa;
  margin-left: 5px;
}

.fleet-item-desc2-light {
  margin-top: 5px;
  display: block;
  text-align: left;
  font-size: 14px;
  font-family: Montserrat-Medium, sans-serif;
  color: #6e6e6e;
  margin-left: 5px;
}

.fleet-item-desc3 {
  display: block;
  text-align: left;
  font-size: 14px;
  font-family: Montserrat-Medium, sans-serif;
  color: #717171;
  margin-left: 5px;
}

.fleet-item-arrival {
  display: block;
  text-align: left;
  font-size: 14px;
  font-family: Montserrat-Medium, sans-serif;
  color: #333333;
}

.fleet-item-edit {
  height: 70px;
  width: 70px;
  margin-right: 10px;
  background-color: #ffb415;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 19px;
}

.fleet-item-delete {
  height: 70px;
  width: 70px;
  background-color: #079992;
  font-size: 19px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: none;
  color: white;
  border-bottom: 4px solid #06726c;
}

.fleet-item-pin {
  height: 35px;
  width: 113px;
  color: #ffcc00;
  background-color: #fff;
  border: 1px solid #ced7e1;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  &:hover {
    background-color: #f0f0f0;
  }
}

body .filter-status-child {
  padding: 10px 16px;
  border-radius: 10px;
  background-color: #f7f7f9;
  font-weight: 600;
  font-size: 13px;

  &:hover {
    background-color: #dbdbdf;
  }

  > .btn-icon {
    margin-right: 13px;
  }

  > div > .spinner-border-sm {
    width: 0.8em;
    height: 0.8em;
  }
}

.hw {
  flex: 0 0 160px;
}

.hwe {
  flex: 0 0 70px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.fr {
  flex: 2;
  /* default 0 */
}

body .input-generic-fleet-searchbar-grp {
  width: 50%;
}

.input-generic-fleet-searchbar-grp-container {
  display: flex;
  justify-content: flex-end;
}

.fleet-item-status {
  white-space: nowrap;
  position: relative;
  margin-left: 30px;
  font-size: 13px;
}

.fleet-item-status::before {
  content: '';
  opacity: 0.7;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -13px;
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: green;
}

.fleet-item-status::after {
  opacity: 0.3;
  position: absolute;
  content: '';
  top: 50%;
  left: -17px;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: green;
  transform-origin: 50% 0 0;
}

.fleet-item-status.ok::after,
.fleet-item-status.ok::before {
  background-color: green;
}

.fleet-item-status.late::after,
.fleet-item-status.late::before {
  background-color: orange !important;
}

.fleet-item-status.issue::after,
.fleet-item-status.issue::before {
  background-color: #ff1400;
}

@keyframes status-blink {
  from {
    opacity: 0.9;
    transform: scale(0.3) translateY(-50%);
  }

  to {
    opacity: 0;
    transform: scale(1) translateY(-50%);
  }
}

.dropdown-filter {
}

.error-message {
  color: rgb(229, 80, 57);
  font-size: 13px;
}

body .input-generic-fleet-searchbar.error {
  border: 2px solid rgb(229, 80, 57);
}

body .no-resume {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.no-resume-img {
  height: 70px;
  margin: 5px auto 15px auto;
}

body .get-resume {
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
}

body .get-resume h5 {
  font-family: Montserrat-SemiBold, sans-serif;
}

body .fleet-item-generic-button {
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #079992;
  font-size: 14px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: none;
  color: white;
  border-bottom: 2px solid #06726c;
  transition: all 0.1s;
}

body .fleet-item-generic-button-small {
  font-weight: bold;
  text-transform: uppercase;
  background-color: #a7a7a7;
  font-size: 14px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: none;
  color: white;
  float: right;
  transition: all 0.1s;
}

body .fleet-item-generic-button-light {
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #ffffff;
  font-size: 14px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: none;
  color: #000000;
  border-bottom: 2px solid #c3c3c3;
  transition: all 0.1s;
}

body .fleet-item-generic-button:hover,
body .fleet-item-generic-button-small:hover {
  color: #f3f3f3;
}

body .fleet-resume h5 {
}

body .fleet-resume p {
  font-size: 13px;
  color: grey;
}

.fleet-resume-container {
  font-family: Montserrat-Medium, sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
}

.error-message-container {
  padding: 10px;
}

.error-message-container > .error-message {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.error-message-container-reserve {
}

.error-message-container-reserve > .error-message-reserve {
  text-align: center;
  font-size: 10px;
  font-weight: bold;
}

.no-tour-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .no-tour-img {
    margin-top: 20px;
    height: 130px;
    width: auto;
  }

  .no-tour-title {
    text-align: center;
    color: #525252;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1;
  }

  .no-tour-text {
    text-align: center;
    font-size: 13px;
    color: #949494;
    font-weight: 500;
    width: 300px;
    margin: 0px 50px 20px 50px;
    line-height: 1;
  }

  .no-tour-text-platform {
    text-align: center;
    font-size: 13px;
    color: #949494;
    font-weight: 500;
    width: 400px;
    margin: 0px 50px 20px 50px;
    line-height: 1;
  }
}

.no-style-a {
  color: #fff;
  text-decoration: none;
}

.no-style-a:hover {
  color: #fff;
}

.header-fleet {
  margin-top: 0 !important;
  padding-top: 30px;
  position: relative;

  .header-fleet-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    position: relative;
    z-index: 1;

    .header-fleet-inner-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .tour-header-number {
      color: #fff;
      background-color: #079992 !important;
      padding: 7px 5px;
      margin-left: 10px;
    }

    .btn-fleet-header {
      padding: 10px 16px;
      border-radius: 10px;
      background-color: #f7f7f9;
      font-weight: 600;
      font-size: 13px;
      margin: 3px 0;
      white-space: nowrap;

      &:hover {
        background-color: #dbdbdf;
      }

      > .btn-icon {
        margin-right: 13px;
      }

      > div > .spinner-border-sm {
        width: 0.8em;
        height: 0.8em;
      }
    }

    .btn-fleet-wrapper {
      position: relative;
      display: inline-block;
    }

    .header-fleet-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      > h3 {
        line-height: 1;
      }
    }
  }
}

.notification-wrapper {
  background-color: #e55039;
  border: 1px solid #cb3d27;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;

  transform: scale(0);
  transition: transform ease-in-out 0.2s;
}

.generic-page-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1500px;
}

.ht {
  font-size: 11px;
}
