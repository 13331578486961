.bl-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  padding: 5px 10px;

  > .left-part {
    display: flex;
    align-items: center;
    flex: 1;

    > .square {
      background-color: #ABB5BF29;
      height: 20px;
      width: 20px;
      border-radius: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;
    }
  }

  > .right-part {
    text-align: end;
  }

  .text {
    color: #0000008C;
    font-size: 11px;
    font-family: "Work Sans", Regular;
  }

  .bl-title {
    color: #0000008C;
    font-size: 14px;
    font-family: "Work Sans", Medium;
    font-weight: 500;
  }
}