@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

:root {
  --swiper-navigation-size: 15px;
  --swiper-navigation-color: white;
}

body {
  margin: 0;
  font-family:
    Montserrat-Medium,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(./assets/fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url(./assets/fonts/Montserrat-SemiBold.ttf);
}
@font-face {
  font-family: Montserrat-Medium;
  src: url(./assets/fonts/Montserrat-Medium.ttf);
}
h2,
h5 {
  font-family: Montserrat-Medium, sans-serif;
}
input:focus,
input:active,
input:focus:active,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@layer components {
  .list-item-title {
    @apply tw-font-work tw-text-smi tw-leading-[20px];
  }

  /* HEADER */

  .header-button-style {
    @apply tw-appearance-none tw-flex tw-py-2 tw-items-center tw-bg-slategray-default tw-cursor-pointer tw-text-smi tw-font-work tw-border-black/5 tw-border tw-text-text-dark-high-importance disabled:tw-cursor-not-allowed disabled:tw-opacity-50 focus:tw-outline-none hover:tw-bg-slategray-hover;
    box-shadow: 0px 4px 2px 0px rgba(255, 255, 255, 0.1) inset;
    transition: all 0.08s ease;
  }

  .header-button-style:active,
  .header-button-style:disabled {
    box-shadow: 0px -4px 2px 0px rgba(255, 255, 255, 0.1) inset !important;
  }

  .header-button-style[type='input']:focus {
    box-shadow: 0px -4px 2px 0px rgba(255, 255, 255, 0.1) inset !important;
  }

  .header-button-style[type='input']:focus {
    @apply tw-bg-slategray-hover;
  }

  /* PAGINATION */

  .agrivitech-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 20px;
  }

  .agrivitech-pagination-list {
    @apply tw-flex tw-justify-center tw-items-center tw-space-x-1 tw-font-work;
    list-style: none;
    font-size: 14px;
    line-height: 14px;
    color: #079992;
    padding: 0;
    margin: 0;
  }

  .agrivitech-pagination-list > li {
    height: 28px;
    min-width: 21px;
    line-height: 28px;
    text-align: center;
    position: relative;
    padding: 0 5px;
  }

  .agrivitech-pagination-list > li > a {
    color: #079992;
    text-decoration: none;
    user-select: none;
  }

  .agrivitech-pagination-list > li.selected {
    border-radius: 4px;
    background-color: #29a9a3;
    height: 28px;
    min-width: 21px;
    line-height: 28px;
    text-align: center;
    color: #fff;
  }

  .agrivitech-pagination-text {
    @apply tw-text-smi tw-font-work tw-text-text-dark-medium-importance;
  }

  /* MODAL */

  .modal-label-group {
    @apply tw-space-y-0.5;
  }

  .modal-label-title {
    @apply tw-text-base tw-leading-4 tw-font-medium tw-text-text-dark-high-importance;
  }

  .modal-label-description {
    @apply tw-text-smi tw-leading-[13px] tw-text-text-dark-medium-importance;
  }
}

.polygon {
  width: 5px;
  height: 5px;
  transform: rotate(90deg);
  background-color: #000; /* La couleur du polygone */
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
