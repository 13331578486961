.product-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  padding: 5px 10px;

  > .left-part {
    display: flex;
    align-items: center;
    flex: 1;
  }

  > .right-part {
    text-align: end;
  }

  .text {
    color: #0000008C;
    font-size: 11px;
    font-family: "Work Sans", Regular;
  }

  .product-title {
    color: #0000008C;
    font-size: 14px;
    font-family: "Work Sans", Medium;
    font-weight: 500;
  }
}