.tableau {
  background-color: white;
  border-radius: 25px;
  margin-top: 30px;
  padding: 20px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  font-size: 15px;
  text-transform: capitalize;
}

.b {
  border: 1px red solid;
}

.error-message {
  background-color: white;
  color: red;
  border-radius: 25px;
  margin-top: 30px;
  padding: 20px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  font-size: 15px;
}

.tableauRow {
  padding: 5px;
  border-radius: 20px;
}

.tableauRow:hover {
  background-color: #f9f9f9;
}

.tableauHeader {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 22px;
  border-bottom: #707070 solid 1px;
  padding-bottom: 5px;
  color: #079992;
}

.header-fleet h3 {
  line-height: 60px;
}

.subtitle {
  font-size: 25px;
  font-weight: 600;
}

.icon {
  color: #079992;
}

.icon.inactive {
  color: #8b8b8b;
  /* cursor:no-drop; */
}

.link {
  cursor: pointer;
  text-align: center;
}

.marginLeft {
  margin: 10px 0px 50px 10px;
  padding-bottom: 100px;
}

.styled-table {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #079992;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
  background-color: #ebecf0;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #079992;
}

.styled-table tbody tr:hover {
  color: #079992;
}

.list-container {
  background-color: white;
  border: 1px solid #0e0e2c1a;
  border-radius: 5px;
}

.list-container-badge {
  padding: 3px 6px;
  font-family: 'Work Sans', sans-serif;
  font-size: 13px;
  background-color: #dde0e4;
  border-radius: 4px;
  display: inline;
  color: #777777;
  font-weight: 500;
}

.input-search {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background-color: rgb(0 0 0/5%);
  height: 33px;
  font-family: 'Work Sans', sans-serif;
  border-radius: 0.25rem;
  border: none;
  padding-right: 1rem;
  padding-left: 1rem;
}
